.titleContainer {
    text-align: center;
}

.titleContainer p {
    text-transform: uppercase;
    font-family: 'Sohne-breit-halbfett', sans-serif;
    color: black;
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: #FF0E0E;
    text-shadow: 0 0 40px #FF0E0E;
    line-height: 48px;
    font-weight: bold;
    font-size: 48px;
    margin-top: 50px;
}

@media (max-width: 992px) {
    .titleContainer p {
        font-size: 36px;
    }
}
@media (max-width: 678px) {
    .titleContainer p {
        font-size: 30px;
    }
}

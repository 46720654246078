.projectCardContainer {
    border: 1px solid red;
    border-radius: 20px;
    position: relative;
    box-shadow: 0px 0px 12px 0px #FF0E0E;
}

.projectsCardMintButton{
    margin-left: 0;
}

@media (max-width: 992px) {
    .projectsCardMintButton{
        margin-left: 1rem;
    }
}

.innerContainer {
    display: flex;
}

.projectCardLeft {
    z-index: 1;
    position: relative;
    width: 50%;
    cursor: pointer;

}

.projectCardLeft a {
    width: 100%;
    height: 100%;
    position: absolute;
}

.projectCardLeft img {
    position: relative;
    z-index: 1;
    width: 100%;
    height: 100%;
    border-radius: 20px 0 0 20px;
}

.projectCardRight {
    z-index: 10;
    position: relative;
    padding: 1rem 2rem;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}

.projectCardRightTop {

    margin-right: 5rem;
    margin-bottom: 1.5rem;
}

.projectCardRightCenter {
    padding-top: 1.5rem;
    border-top: 1px solid rgba(152, 160, 181, .3);
}

.projectCardRightTop p {
    font-family: 'Sohne-breit-halbfett', sans-serif;
    color: white;
    font-size: 2rem;
    width: 95%;
    margin-top: 0 !important;
    margin-bottom: 0 !important;

}

.projectCardSocials {
    margin-bottom: 1rem;
    margin-top: 1rem;
}

.projectCardSocials a:not(:last-child) {
    margin-right: 1rem;
}

.projectCardRightTop p:nth-child(4) {
    font-family: "TT Norms Pro Regular", sans-serif;
    color: #98A0B5;
    font-size: .9rem;

}
.projectCardRightTop p:nth-child(5) {
    font-family: "TT Norms Pro Regular", sans-serif;
    color: #98A0B5;
    font-size: .9rem;

}

.projectCardAttributeContainer {
    margin-bottom: 1rem;
}

.projectCardAttribute {
    display: flex;

}

.projectCardAttribute p:first-child {
    font-family: "TT Norms Pro Regular", sans-serif;
    font-size: 1rem;
    background: -webkit-linear-gradient(#FF0E0E, #FB5300);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    width: 40%;
}

.projectCardAttribute p:last-child {
    font-family: 'Sohne-breit-halbfett', sans-serif;
    color: white;
    margin-left: 1rem;
}

.borderImageFirst {
    position: absolute;
    top: -23px;
    z-index: 100;
}

.borderImageSecond {
    position: absolute;
    bottom: -23px;
    right: 0;
    z-index: 100;
}

.projectCardRightBottom {
    display: flex;
    width: 100%;
    justify-content: flex-end;
    text-align: center;
    position: absolute;
    right: 20px !important;
    top: 20px;
    z-index: 120;

}

.projectCardRightBottom p {
    font-family: 'Sohne-breit-halbfett', sans-serif;
    animation-name: pulseOngoing;
    animation-duration: 2s;
    animation-iteration-count: infinite;
    background: -webkit-linear-gradient(#FF0E0E, #FB5300);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    border-radius: 2rem;
    margin: 0 !important;
    padding: .5rem 1rem;
    font-weight: bold;
    transition: 0.3s all;
    text-transform: uppercase;
}

.projectCardRightBottom p:hover {

    cursor: pointer;
    box-shadow: 0px 0px 12px 0px #FF0E0E;
}

.projectCardRightBottomContainer {
    border-top: 1px solid rgba(152, 160, 181, .3);
    display: flex;
    justify-content: space-between;
    padding-top: 1rem;
}

.projectCardRightBottomContainer a {

    border-radius: 25px;
    padding: .5rem 1rem;
    display: flex;
    justify-content: center;
    align-items: center;

    box-shadow: 0px 0px 12px 0px #FF0E0E;
    transition: all .6s;
}

.projectCardRightBottomContainer a:hover {
    box-shadow: 0px 0px 25px 0px #FF0E0E;
    transition: all .6s;
}

.projectCardRightBottomContainer p {
    background: -webkit-linear-gradient(#FF0E0E, #FB5300);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 1.1rem;
    margin: 0 !important;


}

.projectCardRightBottomContainer a:hover {
    cursor: pointer;
}

.projectCardRightBottomContainer a {
    text-decoration: none;

}

@media (max-width: 1400px) {
    .projectCardLeft {
        width: 100%;
    }
}

@media (max-width: 992px) {
    .projectCardRightTop {
        margin: 0 !important;
    }

    .projectCardRightBottom {
        display: flex;
        width: 100%;
        justify-content: flex-end;

        text-align: center;
        position: absolute;
        right: 10px !important;
        top: -50px;
    }

    .innerContainer {
        flex-direction: column;
    }

    .projectCardAttribute {
        justify-content: space-between;

    }

    .projectCardAttributeContainer {
        margin-top: 1rem;
        margin-bottom: .8rem;
    }

    .projectCardRightBottomContainer {
        justify-content: center;
    }

    .projectCardLeft {
        width: 100% !important;
    }
    .projectCardLeft a{

    }
    .projectCardLeft img {

        width: 100%;
        height: 100%;
        border-radius: 20px 20px 0 0;
    }

    .projectCardRightBottom p {
        box-shadow: 0px 0px 22px 0px #FF0E0E;
    }

    .projectCardRightTop p {
        font-size: 1.5rem;
        text-align: center;
    }

    .projectCardSocials {
        display: flex;
        justify-content: center;
    }

    .projectCardDescription {
        text-align: center;
    }
}

.modal-content {
    background: black url("../../assets/menubg.png") no-repeat;
    background-size: cover;
    opacity: 1;
}

@keyframes pulseOngoing {
    0% {
        box-shadow: 0px 0px 32px 0px #FF0E0E;
        transform: scale(1);
    }
    50% {
        box-shadow: 0px 0px 12px 0px #FF0E0E;
        transform: scale(1.05);
    }
    100% {
        box-shadow: 0px 0px 32px 0px #FF0E0E;
        transform: scale(1);
    }
}

.recordLabelTitle {
    font-family: 'Sohne-breit-Buch', sans-serif;
    font-size: 24px;
    text-align: center;
    color: white;
}

.recordLabelContainer {
    margin-top: 150px;
    margin-bottom: 250px;
}

.rocknverse img {
    width: 40%;
    height: auto;
    filter: drop-shadow(0 0 20px #FB5300);
}

.bohem img {
    filter: drop-shadow(0 0 15px #FB5300);

}

.bohem img:hover {
    filter: drop-shadow(0 0 10px #FB5300);
    cursor: pointer;
}

.gallerySection {
    font-family: 'Sohne-breit-halbfett', serif;
    font-weight: normal;
    font-size: 1rem;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 3rem;
    text-align: justify;
}

.gallerySection button {
    background: black;
    font-size: 16px;
    font-family: 'Sohne-breit-halbfett', serif;
    color: white;
    padding: .8em 1.5rem;
    border: 1px solid #FF0E0E;
    margin-bottom: 0 !important;
    border-radius: 2rem;
    transition: 0.3s all;
    width: 200px;
    text-align: center;
    box-shadow: 0 0 12px 0 #FF0E0E;

}

.gallerySection button:hover {
    cursor: pointer;
    box-shadow: 0px 0px 32px 0px #FF0E0E;
}

.sponsorsHeading {
    margin-top: 150px;
}

.xtinge {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 5rem;
    margin-top: 75px;
}

.xtinge img {
    width: 100%;
    height: auto;
}

.dominage {
    display: flex;
    justify-content: center;
}

.dominage img {
    width: 100%;
    height: auto;
}

.tomtom {
    max-width: 250px;
    display: flex;
    align-items: center;
    justify-content: center;
    
    
}

.tomtom img {
    width: 100%;
    height: auto;
    text-align: center;
    
}

.ct {
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
}

.ct img {
    width: auto;
    height: 20%;
}

.pixxi {
    display: flex;
    align-items: center;
    margin-left: 5rem;

}

.pixxi img {
    width: 100%;
    height: auto;
}

.SponsorContainerCt {
    margin-top: 30px !important;
    display: flex;
    padding: 10px;
}

.description {
    font-family: "TT Norms Pro Regular", sans-serif;
    font-size: 18px;
    line-height: 32px;
    color: #98A0B5;
    text-align: center;
}

.galleryImage {
    width: 100%;
    height: auto;
}

.galleryNext {

    height: 30px;
    width: 30px;
    color: white;
    border-radius: 50%;
    border: none;
    background: linear-gradient(#FF0E0E, #FB5300);
}

.galleryBack {
    color: white;
    height: 30px;
    width: 30px;
    border: 1px solid #98A0B5;
    background: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
}

.bigGalleryContainer {
    position: relative;
}

.galleryButtonContainer {
    position: absolute;
    top: 0;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 1rem;
}

.modalFooter {
    background: linear-gradient(#FF0E0E, #FB5300);
    padding: .2rem .4rem;
    border-radius: 10px;
    transition: all .2s;
}

.modalFooter:hover {
    background: linear-gradient(#FF0E0E, #FB5300);
    padding: .2rem .4rem;
    border-radius: 5px;
    cursor: pointer;
}

@media (max-width: 992px) {
    .recordLabelImgContainer {
        --bs-gutter-x: 0 !important;
    }
}
@media (max-width: 1024px) {
    .xtinge {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 75px;
        width: 65%;

        color: #EE2E2A ;
    }
    .pixxi {
        margin-top: 75px;
        width: 70%;
    }
    .ct{
        margin-left:80px ;
        width: 80%;
    }
}
@media (max-width: 480px) {
    .xtinge {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 75px;
        width: 75%;
        margin-right:25px;
    }
    .pixxi {
        margin-top: 75px;
        width: 75%;
    }
    .ct{
        margin-left:80px ;
        width: 70%;
    }
}


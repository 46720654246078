.headerContainer {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    font-family: 'Sohne-breit-buch', sans-serif;
    background-color: transparent;
    width: 100%;
}

.headerStroke {
    color: black;
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: #FF0E0E;
    text-shadow: 0 0 40px #FF0E0E;
}

.headerLeft {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: start;
    color: white;
}

.headerLeftHeading {
    font-size: 3.5rem;
    font-family: 'Sohne-breit-halbfett', sans-serif;
}

.headerLeftHeading p {
    display: flex;
    margin-bottom: -.5rem !important;
    font-size: 40px;
}

.headerLeftDescription {
    font-family: "TT Norms Pro Regular", sans-serif;
    color: #98A0B5;
}

.headerLeftDescription p {
    max-width: 700px;
    width: auto;
}

.headerLeftDescription p:last-child {
    text-align: justify;
}

.headerImg {
    width: 100%;
    height: auto;
    margin-left: -3rem;
    filter: drop-shadow(0 0 20px #FF0E0E);
}



.headerLinkContainer {
    display: flex;
    flex-direction: row;
}

.headerLeftNote {
    color: #FFFFFF;
    display: flex;
    border-radius: 50px;
    background: linear-gradient(#FF0E0E, #FB5300);
    width: 11rem;
    height: 3rem;
    transition: ease all 1s;
    margin-right: 15px;


}

.headerRightNote {
    color: #FFFFFF;
    display: flex;
    border-radius: 50px;
    background: linear-gradient(#FF0E0E, #FB5300);
    width: 10rem;
    height: 3rem;
    transition: ease all 1s;

}

.headerLeftNote div {
    margin-left: .5rem;
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 100%;
}

.headerRightNote div {
    margin-left: .5rem;
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.headerLeftNote div p {
    margin-bottom: 0 !important;
    display: flex;
    width: 100%;
    font-size: 14px;
    text-align: center;

}

.headerRightNote div p {
    margin-bottom: 0 !important;
    display: flex;
    width: 100px;
    font-size: 14px;
}


.headerLeftNote:hover {
    transition: ease all 1s;
    box-shadow: 0px 0px 50px 0px #FB5300;
}

.headerRightNote:hover {
    transition: ease all 1s;
    box-shadow: 0px 0px 50px 0px #FB5300;
}

.modalParagraph {
    width: 100%;
    text-align: center;
}

.modalCloseButton {
    display: flex;
    justify-content: center;
    align-items: center;
}

.modalCloseButton p {
    margin: 0 !important;
    background: linear-gradient(#FF0E0E, #FB5300);
    padding: .5rem 1rem;
    border-radius: 10px;
    transition: all .2s;
    display: flex;
    width: 30%;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.headerRight {
    margin-left:5rem;
    width: 55%;
    display: flex;
    justify-content: flex-end;
}


@media (min-width: 992px) {
    .headerImgMobile {
        display: none;
    }
}

@media (max-width: 992px) {
    .headerLinkContainer {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .headerRight {
        width: 100%;
        margin-left: 0;
    }

    .headerImg {
        display: none;
    }

    .headerImgMobile {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
    }

    .headerImg {
        width: 300%;
        height: auto;
    }

    .headerContainer {
        flex-direction: column-reverse;
    }

    .headerLeft {
        justify-content: center;
        text-align: center;
        align-items: center;
    }

    .headerLeftNote {

        width: 200px;
        display: flex;
        margin-bottom: 20px;

    }

    .headerRightNote {

        width: 100%;
        display: flex;

    }

    .headerLeftHeading {
        width: 100%;
        font-size: 2.5rem;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }

    .headerLeftNote div p {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .headerLeftNote div {
        margin-left: 0 !important;
    }
}

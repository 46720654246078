.navbarContainer {
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 1rem 0;
    font-family: 'Sohne-breit-buch', sans-serif;

}

.whitepaper {
    pointer-events: none;
}

.navbarCenter {
    display: flex;
    justify-content: space-between;
}

.navbarCenterLeft {
    margin-right: 3rem;
    display: flex;
}

.navbarLink:last-child {
    position: relative;
}

.navbarLink:last-child div {
    position: absolute;
    font-family: "TT Norms Pro Regular", sans-serif;
    font-size: 10px;
    top: -20px;
    right: 0;
    background: -webkit-linear-gradient(#FF0E0E, #FB5300);
    padding: .2rem .4rem;
    border-radius: 5px;
}

.navbarCenterLeft a {
    text-decoration: none;
    color: white;
}

.navbarCenterLeft a:not(:last-child) {
    margin-right: 3rem;
}

.navbarCenterRight {
    display: flex;
    justify-content: center;
    align-items: center;
}


.navbarCenterRight a:not(:last-child) {
    margin-right: 1rem;
}
.navbarCenterRight a > img:last-child {
    width: 24px;
    height: 24px;
}
.btn:focus, .btn:active {
    outline: none !important;
    box-shadow: none;
}

.globe {
    color: white;
    box-shadow: 0 0 32px 4px #FF0E0E;
    transition: 0.6s all;
    border-radius: 50%;

}

.globe:hover {
    cursor: pointer;
    box-shadow: 0 0 10px 2px #FF0E0E;
    transition: 0.6s all;
}

.dropdown-toggle {
    color: white;
}

.dropdown-toggle:hover {
    color: white;
}

.show {
    background-color: black;
}

.dropdown-item-text li {
    color: white;
}

.dropdown-item {
    color: white;
}

.dropdown-item:hover {
    color: #FF0E0E;
    background: black;
}

.connectWallet {
    color: white;
    padding: 1rem 2rem;
    border: 1px solid #FF0E0E;
    margin-bottom: 0 !important;
    border-radius: 2rem;
    box-shadow: 0 0 12px 0 #FF0E0E;
    transition: 0.6s all;
    margin-left: 12px;
}

.connectWallet:hover {
    cursor: pointer;
    box-shadow: 0 0 32px 0 #FF0E0E;
    transition: 0.6s all;
    color: red;
}

#menu__toggle {
    opacity: 0;
}

#menu__toggle:checked + .menu__btn > span {
    transform: rotate(45deg);
}

#menu__toggle:checked + .menu__btn > span::before {
    top: 0;
    transform: rotate(0deg);
}

#menu__toggle:checked + .menu__btn > span::after {
    top: 0;
    transform: rotate(90deg);
}


.menu__btn {
    position: fixed;
    top: 50px;
    right: 20px;
    width: 26px;
    height: 26px;
    cursor: pointer;
    z-index: 1000000;
}

.menu__btn > span,
.menu__btn > span::before,
.menu__btn > span::after {
    display: block;
    position: absolute;
    width: 100%;
    height: 2px;
    background-color: white;
    transition-duration: .25s;
}

.menu__btn > span::before {
    content: '';
    top: -8px;
}

.menu__btn > span::after {
    content: '';
    top: 8px;
}

.menu__box {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: fixed;
    top: 0;
    right: -110%;
    width: 100vw;
    height: 100vh;
    margin: 0;
    text-align: center;
    padding-top: 50px;
    list-style: none;
    background: black;
    box-shadow: 2px 2px 6px rgba(0, 0, 0, .4);
    transition-duration: .25s;
    z-index: 10000;
}

.menu__item {
    display: block;
    padding: 12px 24px;
    color: #333;
    font-family: 'Roboto', sans-serif;
    font-size: 35px;
    font-weight: 600;
    text-decoration: none;
    transition-duration: .25s;
}


@media (max-width: 992px) {
    .dropdown {
        display: flex;
        margin-left: -45px;
        margin-right: 25px;
    }

    .navbarCenterLeft {
        margin-left: 25px;
    }

    .navbarCenterLeft a {
        font-size: 14px !important;
    }

    .connectWallet {
        font-size: 12px;
        padding: 0.5rem 2rem;
        margin-left: 20px;
    }

}

@media (max-width: 500px) {
    .navbarContainer {
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .dropdown {
        margin: 20px 0 0 0 !important;
    }

    .connectWallet {
        margin: 0 !important;
    }


}

@media (min-width: 1200px) {
    .hamburger-menu {
        display: none;
    }

}

@media (max-width: 1200px) {
    .menu__item{
    display: block;
    padding: 12px 24px;
    color: #333 !important;
    font-family: 'Roboto', sans-serif;
    font-size: 35px;
    font-weight: 600;
    text-decoration: none;
    transition-duration: .25s;
}
    .menu__item:hover{
        color: #0A58CA !important;
    }
    .menu__item{
        position: relative;
    }
    .menu__item div{
        position: absolute;
        font-family: "TT Norms Pro Regular", sans-serif;
        font-size: 10px;
        top: 0;
        right: 0;
        background: -webkit-linear-gradient(#FF0E0E, #FB5300);
        padding: .2rem .4rem;
        border-radius: 5px;
        color: #333333;

    }

    .navbarCenter {
        display: none;
    }

    .navbarLeft {
        width: 50%;
        z-index: 9999;
    }

    .navbarRight {
        left: -70px;
        font-size: 14px;
        border-radius: 50%;
        z-index: 9999;
    }

    .connectWallet {
        padding: 0.4rem;
        margin-right: 55px;
        text-align: center;
        pointer-events: none;

    }
}

@media (max-width: 1400px) {
    .navbarCenterLeft a:not(:last-child) {
        margin-right: 1rem;
    }

}


/*MODAL*/

.modal-content {
    background-color: #000;
    border: 1px solid #98A0B5;
    box-shadow: 0 0 5px 0 #98A0B5;
    color: #FFF;
}

.connectWalletContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 1rem 2rem;
}

.connectWalletButton {
    font-family: 'Sohne-breit-buch', sans-serif;
    color: white;
    padding: 1rem 2rem;
    border: 1px solid #FF0E0E;
    width: 200px;
    border-radius: 2rem;
    box-shadow: 0 0 16px 0 #FF0E0E;
    transition: 0.3s all;
    background-color: transparent;
}

.connectWalletButton:not(:last-child) {
    margin-bottom: 1rem;
}

.connectWalletHeading h2 {
    font-family: 'Sohne-breit-halbfett', sans-serif;
    text-align: center;
    margin-top: 1rem;
    margin-bottom: 1rem;
}

.connectWalletMainContainer {
    position: relative;
}

.closeButton {
    font-family: 'Sohne-breit-halbfett', sans-serif;
    position: absolute;

    padding: .5rem 1rem;
    border-radius: 50%;
    top: 10px;
    right: 10px;
}

.closeButton:hover {
    cursor: pointer;
}

.connectWalletButton:hover {
    box-shadow: 0 0 10px 0 #FF0E0E;
}

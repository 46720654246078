.upComingProjectCardContainer {
    height: 80%;
    border: 1px solid red;
    border-radius: 20px;
    position: relative;
    box-shadow: 0px 0px 12px 0px #FF0E0E;
}

.innerContainer {
    display: flex;
}

.upComingProjectCardLeft {
    z-index: 1;
    position: relative;
    width: 50%;

}

.upComingProjectCardLeft img {

    width: 100%;
    height: 100%;
    border-radius: 20px 0 0 20px;
}

.upComingProjectCardRight {
    z-index: 10;
    position: relative;
    padding: 3rem 1rem;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: 100%;
}
.upComingProjectCardAttribute {
    display: flex;
}

.upComingProjectCardAttribute p:first-child {
    font-family: "TT Norms Pro Regular", sans-serif;
    font-size: 1rem;
    background: -webkit-linear-gradient(#FF0E0E, #FB5300);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.upComingProjectCardAttribute p:last-child {
    font-family: 'Sohne-breit-halbfett', sans-serif;
    color: white;
    margin-left: 1rem;
}
.comingSoon{
    color: #FFFFFF;
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;
    font-size: 30px;
    text-transform: uppercase;
    font-family: 'Sohne-breit-halbfett', sans-serif;
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: #FF0E0E;
    text-shadow: 0 0 40px #FF0E0E;
    font-weight: bold;
    margin-top: 20px;
}
.upComingBorderImageFirst {
    position: absolute;
    top: -23px;
    z-index: 100;
}

.upComingBorderImageSecond {
    position: absolute;
    bottom: -23px;
    right: 0;
    z-index: 100;
}

.upComingProjectCardRightBottom {
    display: flex;
    width: 100%;
    text-align: center;
}


.upComingProjectCardRightBottom p:hover {

    cursor: pointer;
    box-shadow: 0px 0px 12px 0px #FF0E0E;
}

@media (max-width: 992px) {
    .innerContainer {
        flex-direction: column;
    }

    .upComingProjectCardAttribute {
        justify-content: space-between;

    }

    .upComingProjectCardAttributeContainer {
        margin-top: 1rem;
        margin-bottom: .8rem;
    }
    .upComingProjectCardLeft {
        width: 100%;
    }
    .upComingProjectCardLeft img {

        width: 100%;
        height: 100%;
        border-radius: 20px 20px 0 0;
    }
}

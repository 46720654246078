.newRoadmapContainer {
    margin-top: 150px;
    position: relative;
}

.roadmapTitle {
    font-family: 'Sohne-breit-Buch', sans-serif;
    font-size: 24px;
    text-align: center;
    color: white;
}

.roadmapImage {
    display: flex;
    justify-content: flex-end;
    position: relative;
    height: 4500px;
    left: 800px;
}

.roadmapItems{
    position: relative;
    display: flex;
    flex-direction: column;
    padding-top: 760px;
    gap: 2rem;
    margin-right: 255px;
}

.roadmapImage img {
    width: auto;
    height: 100%;
    filter: drop-shadow(0 0 15px #FF0E0E);
    position: absolute;
}

.roadmapItems > .guitarLineSection1 {
    position: absolute;
    right: 143px;
    top: 350px;
}

.roadmapItems > .guitarLineSection2 {
    position: absolute;
    right: 172px;
    top: 450px;
}

.roadmapItems > .guitarLineSection3 {
    position: absolute;
    right: 200px;
    top: 565px;
}


@media (max-width: 1400px) {
    .roadmapImage {
        display: flex;
        justify-content: flex-end;
        position: relative;
        height: 4000px;
        left: 750px;
    }
    

    .roadmapItems > .guitarLineSection1 {
        position: absolute;
        right: 126px;
        top: 311px;
    }

    .roadmapItems > .guitarLineSection2 {
        position: absolute;
        right: 154px;
        top: 412px;
    }

    .roadmapItems > .guitarLineSection3 {
        position: absolute;
        right: 180px;
        top: 504px;
    }

    .roadmapItems{
        position: relative;
        display: flex;
        flex-direction: column;
        padding-top: 680px;
        gap: 2rem;
        margin-right: 190px;
    }
}
@media (max-width: 1024px) {
    .roadmapImage {
        display: flex;
        justify-content: flex-end;
        position: relative;
        height: 4200px;
        left: 850px;
    }

    .roadmapTitle {
        font-family: 'Sohne-breit-Buch', sans-serif;
        font-size: 24px;
        text-align: center;
        color: white;
    }
    .roadmapItems{
        position: relative;
        display: flex;
        flex-direction: column;
        padding-top: 700px;
        gap: 1.2rem;
        margin-right: 120px;
    }

    .roadmapItems > .guitarLineSection1 {
        position: absolute;
        right: 145px;
        top: 328px;
    }

    .roadmapItems > .guitarLineSection2 {
        position: absolute;
        right: 172px;
        top: 409px;
    }

    .roadmapItems > .guitarLineSection3 {
        position: absolute;
        right: 201px;
        top: 504px;
    }

}

@media (max-width: 768px) {
    .roadmapImage {
        display: flex;
        justify-content: flex-end;
        position: relative;
        height: 3000px;
        left: 690px;
    }

    .roadmapTitle {
        font-family: 'Sohne-breit-Buch', sans-serif;
        font-size: 24px;
        text-align: center;
        color: white;
    }
    .roadmapItems{
        position: relative;
        display: flex;
        flex-direction: column;
        padding-top: 490px;
        gap: 0.3rem;
        margin-right: 20px;
    }

    .roadmapItems > .guitarLineSection1 {
        position: absolute;
        right: 86px;
        top: 234px;
    }

    .roadmapItems > .guitarLineSection2 {
        position: absolute;
        right: 104px;
        top: 282px;
    }

    .roadmapItems > .guitarLineSection3 {
        position: absolute;
        right: 130px;
        top: 362px;
    }

   
}



.rockCommunityCardContainer {
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
}
.rockCommunityCardImg img {
    filter: drop-shadow(0 0 15px #FF0E0E);
}

.rockCommunityCardHeading {
    font-family: 'Sohne-breit-Buch', sans-serif;
    text-align: center;
    height: 60px;
}

.rockCommunityCardParagraph {
    font-family: "TT Norms Pro Regular", sans-serif;
    text-align: center;
    color: #98A0B5;
}

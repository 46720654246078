.teamMemberTitle {
    font-family: 'Sohne-breit-Buch', sans-serif;
    font-size: 24px;
    text-align: center;
    color: white;
}
.teamMembersContainer {
    margin-top: 150px;
}
.SocialMediaIcons {
    display: flex;
    justify-content: center;
}
.SocialMediaIcons a {
    display: flex;
    justify-content: center;
    width: 24px;
    height: 24px;
    margin-right: 10px;
}
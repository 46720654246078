.projectsContainer {
    margin-top: 150px;
}

.projectsSubTitle {
    font-family: 'Sohne-breit-Buch', sans-serif;
    color: white;
    text-align: center;
    font-size: 24px;
}

.projectsSubTitle2 {
    font-family: 'Sohne-breit-Buch', sans-serif;
    color: #FF0E0E;
    text-align: center;
    font-size: 24px;
    margin-top: 100px;
    margin-bottom: 50px;
}
.projectsSubTitle3 {
    font-family: 'Sohne-breit-Buch', sans-serif;
    color: #98A0B5;
    text-align: center;
    font-size: 24px;
    margin-top: 100px;
    margin-bottom: 50px;
}

@media (max-width: 992px) {
    .projectsSubTitle2 {
        line-height: 3;
        font-size: 16px;
        margin-bottom: 75px;
        margin-top: 75px;
    }
}

.teamMemberContainer {
    position: relative;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    margin-bottom: 1rem;
    margin-top: 50px;
}

.teamMemberContainer img {
    width: 350px;
    height: 260px;
    max-width: 100%;
    border-radius: 10px;
    border: 1px solid #FF0E0E;
    box-shadow: 0 0 15px #FF0E0E;
    justify-content: center;
    align-items: center;
}

.teamMemberText1 {
    font-family: 'Sohne-breit-halbfett', serif;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 1rem;
    font-size: 18px;
    font-weight: 700;
    text-align: center;
    color: white;
    width: 100%;

}


.teamMemberText2{
    font-family: "TT Norms Pro Regular", sans-serif;
    font-size: 16px;
    color: #98A0B5;
    text-align: center;
    margin-bottom: -20px;

}

@media (max-width: 767px) {
    .teamMemberContainer img {
        width: 350px;
        height: auto;
        max-height: 275px;
        max-width: 100%;
        border-radius: 10px;
        border: 1px solid #FF0E0E;
        box-shadow: 0 0 15px #FF0E0E;
        justify-content: center;
        align-items: center;
    }
}
@media (max-width: 450px) {
    .teamMemberContainer img {
        width: 100%;
        height: auto;
        max-width: 100%;
        border-radius: 10px;
        border: 1px solid #FF0E0E;
        box-shadow: 0 0 15px #FF0E0E;
        justify-content: center;
        align-items: center;
    }
}

.pageContainer {
    margin-top: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.div1 {
    display: flex;
    justify-content: center;
}
.div2 {
    display: flex;
    justify-content: center;
}.div3{
    margin-top: 50px;
     display: flex;
     justify-content: center;
 }


.notFoundtitle {
    font-size: 200px;
    color: white !important;
    font-weight: bold;
    filter: drop-shadow(0 0 20px #FF0E0E);
}

.notFoundtitle:hover {
    pointer-events: none !important;
}

.notFoundDesc {
    font-size: 50px;
    color: white !important;
}
.hPButton {
    border: 1px solid #FF0E0E;
    color: white;
    padding: 1rem 2rem;
    border-radius: 2rem;
    box-shadow: 0 0 16px 0 #FF0E0E;
    transition: 0.3s all;
    background-color: transparent;
}
.hPButton:hover {
    box-shadow: 0 0 30px 0 #FF0E0E;
}

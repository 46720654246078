.rockCommunityContainer {
    margin-top: 8rem;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.rockCommunityCards {
    display: flex;
    align-items: center;
    justify-content: center;
}

.rockCommunityTitle {
    position: relative;
    width: 100%;
}

.rockCommunityTitleText {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: 'Sohne-breit-halbfett', sans-serif;
    z-index: 10;
    position: relative;

}

.rockCommunityTitleBackground img {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    z-index: 1;
    width: 100%;
    height: auto;
}

.rockCommunityTitleFirst {
    font-size: 2rem;
    color: white;
    margin-bottom: .5rem;
}

.rockCommunityTitleSecond {
    color: white;
    margin-top: .5rem;
    font-size: 2rem;
}

@media (max-width: 992px) {
    .rockCommunityTitleFirst {
        font-size: 1.5rem;

    }

    .rockCommunityTitleSecond {
        font-size: 1.5rem;
        text-align: center;
    }
}

.whitepaperBoxContainer {
    margin-top: 4rem;
    border: 1px solid #FF0E0E;
    box-shadow: 0 0 5px #FF0E0E;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 30%;
    padding: 1rem 2rem;
}

.whitepaperBoxHeading {
    color: white;
    text-align: center;
    font-family: 'Sohne-breit-halbfett', sans-serif;
    margin-bottom: 2rem;
}

.whitepaperBoxDownload {
    color: #FF0E0E;
    border-radius: 25px;
    box-shadow: 0 0 12px 0 #FF0E0E;
    padding: .5rem 1rem;
    font-family: 'Sohne-breit-halbfett', sans-serif;
    cursor: pointer;
    transition: .2s all;
}

.whitepaperBoxDownload a {
    color: white;
}

.whitepaperBoxDownload:hover {
    box-shadow: 0 0 4px 0 #FF0E0E;
}

.whitepaperIconContainer {
    color: white;
    margin-bottom: 2rem;
}

.whitepaperTextContainer {
    color: white;
    text-align: center;
    margin-bottom: 1.5rem;

}

@media (max-width: 992px) {
    .whitepaperBoxContainer {
        width: 100%;
    }
}

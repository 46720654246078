.mintContainer {
    height: 100%;
    border: 1px solid red;
    border-radius: 20px;
    position: relative;
    box-shadow: 0px 0px 30px 0px #FF0E0E;
}

.mintLeft img {
    height: auto;
    width: 100%;
    max-width: 100%;
}
.text-white{
   font-size: 25px;
}
.mainMintContainer {
    padding: 0 6rem;
    margin-top: 6rem;
    margin-bottom: 6rem;
    width: 80%;
}


.mintImage {
    border-radius: 20px;
}


.mintRight {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    height: 100%;
    width: 100%;
}

.mintLeft {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
}

.mintButton button {
    padding: 1rem 4rem;
    border: 1px solid red;
    border-radius: 4000px;
    position: relative;
    box-shadow: 0px 0px 30px 0px #FF0E0E;
    background-color: transparent;
    color: white;
    transition: all .2s;
}
.mintButton button:hover {

    box-shadow: 0px 0px 10px 0px #FF0E0E;

}

.mintAmount {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 10px;
    color: black;
    background-color: rgba(255,255,255);
}

.mintAmount > .right {
    margin-left: 1em;
    padding: .5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    user-select:none;
}

.mintAmount > .right p {
    margin-bottom: 0 !important;
    font-weight: bold;
    font-size: 1rem;
}

.mintAmount > .right:hover {
    cursor: pointer;
}

.mintAmount > .left {
    margin-right: 1em;
    padding: .5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    user-select:none;
}

.mintAmount > .left p {
    margin-bottom: 0 !important;
    font-weight: bold;
    font-size: 1.5rem;
}

.mintAmount > .left:hover {
    cursor: pointer;
}

.mintAmount input {
    border: none;
    width: 10%;
}

.mintAmount input:focus {
    border: none !important;
    outline-width: 0;
}

.mintAmount input:active {
    border: none;
}

@media (max-width: 1400px) {
    .text-white{
        font-size: 20px;
        display: flex;
        justify-content: center;
    }
    .mintButton {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
}


@media (max-width: 1200px) {
    .minLeft img {
        width: 90%!important;
        height: 100%;
    }
    .mainMintContainer{
        margin-top: 6rem;
        margin-bottom: 6rem;
        width: 100%;
    }
.text-white{
    font-size: 20px;
    display: flex;
    justify-content: center;
    margin-top: 50px;
}
    .mintAmount{
        margin: 50px 0 50px 0;
    }
    .mintButton {
        margin: 50px 0 50px 0;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
}
@media (max-width: 1200px) {
    .mainMintContainer {
        padding: 0 12rem 0 12rem;
        margin-top: 6rem;
        margin-bottom: 6rem;
        width: 100%;
    }
}
@media (max-width: 800px) {
    .mainMintContainer {
        padding: 0 12rem 0 12rem;
        margin-top: 6rem;
        margin-bottom: 6rem;
        width: 100%;
    }
}

.footerContainer {
    border-top: 1px solid #FF0E0E;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    background: url("../../assets/pngwing 5.png") no-repeat center;
    background-size: cover;
    padding: 2rem;
    font-family: 'Sohne-breit-buch', sans-serif;
}


.footerLogo {
    height: auto;
    width: 200px;
}

.footerMenu a {
    text-decoration: none;
    color: white;
}

.footerMenu a:not(:last-child) {
    margin-right: 2rem;
}

.footerCopyright p {
    color: #98A0B5;
    margin-bottom: 3rem;
}

.bohem img {
    width: 50%;
    height: auto;
}

.mail {
    margin-top: 30px;
}

.mail a {
    color: white;
    text-decoration: none;
}
.ct img {
    width: 80%;
    height: auto;
}

.footerSocials {
    margin-top: 2rem;
}

.footerSocials a:not(:last-child) {
    margin-right: 1rem;
}

@media (max-width: 992px) {
    .footerMenu a:not(:last-child) {
        margin-right: 0;
    }

    .footerMenu {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }
    .footerMenu a {
        margin-bottom: 30px!important;
    }
    .footerSponsorContainer {
        display: none;
    }
}

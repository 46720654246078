.whitePaperContainer {
    display: flex;
    margin-top: 5rem;
    margin-bottom: 5rem;
    position: relative;

}

.whitePaperCloseButton {
    position: absolute;
    color: white;
    display: flex;
    justify-content: flex-end;
    top: -30px;
    right: 0;
    cursor: pointer;
}

.whitePaperMenu {
    padding: 1rem;
    position: relative;
    width: 80%;
    border: 1px solid #FF0E0E;
    box-shadow: 0 0 5px #FF0E0E;
    border-radius: 10px;


}

.whitePaperMenu img {
    position: absolute;
    top: -25px;
}

.menuItem {
    color: white;
    text-align: center;
    font-family: 'Sohne-breit-halbfett', sans-serif;
    cursor: pointer;
}

.menuItemActive {
    color: #FF0E0E;
    text-align: center;
    font-family: 'Sohne-breit-halbfett', sans-serif;
    cursor: pointer;
}

.whitePaperHeading {
    border-bottom: 1px solid rgba(255, 14, 14, .2);

}

.whitePaperHeading h2 {
    text-transform: uppercase;
    font-family: 'Sohne-breit-halbfett', sans-serif;
    color: black;
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: #FF0E0E;
    text-shadow: 0 0 40px #FF0E0E;
    line-height: 48px;
    font-weight: bold;
    font-size: 48px;
}

.whitePaperHeading p {
    color: #FFFFFF;
    margin-top: 2rem;
    font-family: "TT Norms Pro Regular", sans-serif;
}

.whitePaperContent {
    margin-top: 1rem;
    margin-bottom: 2rem;
}

.whitePaperContent img {
    width: 100%;

}

.whitePaperContent h4 {
    color: white;
    font-family: "TT Norms Pro Regular", sans-serif;
}

.whitePaperContent p {
    color: #98A0B5;
    margin-top: 1rem;
    margin-bottom: 2rem;
}

@media (max-width: 992px) {
    .whitePaperMenu {
        width: 100%;
        margin-bottom: 2rem;
    }
}

@media (max-width: 480px) {
    .whitePaperHeading h2 {
        font-size: 38px;
    }
}


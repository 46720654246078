@font-face {
    font-family: 'Sohne-breit-buch';
    src: url("./fonts/SohneBreit-Buch.otf");
}

@font-face {
    font-family: 'Sohne-breit-halbfett';
    src: url("./fonts/SohneBreit-Halbfett.otf");
}

@font-face {
    font-family: 'TT Norms Pro Regular';
    src: url("./fonts/TT Norms Pro Regular.otf");
}

body {
    background-color: black;
    background-image: url("assets/headerbg.png");
    background-repeat: no-repeat;
    background-size: 100vw;
    overflow-x: hidden !important;
    overflow-y: scroll;
}

html {
    overflow-x: hidden !important;
    overflow-y: scroll;
}

body > iframe {
    pointer-events: none;
}

* img {
    pointer-events: none;
    user-select: none;
}


a {
    text-decoration: none;
}




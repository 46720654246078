
.videoContainer {
    width: 100%;
    padding-bottom: 1rem;
    z-index: 1;
    margin-top: 150px;
    display: flex;
    justify-content: center;
    border-radius: 10px;
    overflow: hidden;
    filter: drop-shadow(0 0 20px #FF0E0E);

}

.videoMain {
    display: flex;
    justify-content: center;
    width: 100% !important;
    height: 650px !important;
}

@media (max-width: 678px) {
    .videoMain {
        height: 325px !important;
    }
}

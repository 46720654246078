.descriptionContainer {
    margin-top: 150px;
}

.upperTitles {
    font-family: 'Sohne-breit-halbfett', sans-serif;
    font-size: 24px;
    text-transform: uppercase;
    text-align: center;
    color: white;
    word-break: break-word;
}

.descriptionImg {
    width: 500px;
    height: 297px;
    display: block;
    margin: 0 auto 50px;

}


.lowerTitle p {
    font-family: 'Sohne-breit-Buch', sans-serif;
    color: white;
    text-align: center;
    font-size: 36px;
    margin-top: 20px;
}

.lowerTitle p span {
    color: #FF0E0E;
}

.description {
    font-family: "TT Norms Pro Regular", sans-serif;
    font-size: 24px;
    line-height: 32px;
    color: #98A0B5;
    text-align: center;
    display: flex;
    justify-content: center;
}

.description p {
    width: 50%;
    font-size: 20px;
}

.descriptionImg {
    filter: drop-shadow(0 0 20px #FF0E0E);
    height: 100%;
}

@media (max-width: 992px) {
    .descriptionImg {
        height: auto;
        width: 100%;
    }

    .upperTitles {
        font-size: 16px;
    }

    .lowerTitle p {
        font-size: 24px;
    }

    .description {
        font-size: 16px;
    }

    .description p {
        font-size: 18px;
        width: 100%;
    }
}

@media (max-width: 678px) {
    .upperTitles {
        font-size: 15px;
    }
}

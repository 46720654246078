.wrapper {
    display: flex;
    justify-content: flex-start;
    transition: .4s;
}
.wrapper span {
    font-size: 14px;
    transition: .4s;
    
}
.wrapper.directionLeft {
    flex-direction: row-reverse;
    transition: .4s;
}

.wrapper.typeFirst{
    text-decoration: #FF0E0E line-through;
}



.wrapper:hover{
    background-color: transparent;
    padding-left: 10px;
    border-radius: 30px;
    text-decoration: none;
}

.wrapper:hover .guitarLineDescription{
    color: #FF0E0E;    
    text-decoration: none;

}


.wrapper:hover .guitarLineTitle{
    color: #FF0E0E;    
    text-decoration: none;
}


.wrapper:hover .guitarLineSubDescription{
    color: #FF0E0E;  
    text-decoration: none;  

}

.wrapper:hover .line{
    background-color: #FF0E0E;

}

.wrapper:hover .dot{
    background-color: #FF0E0E;

}
.line {
    height: 3px;
    width: 220px;
    background-color: white;
    transition: .4s;
}

.wrapper.typeCurrent > .line {
    background-color: #FF0E0E;
    transition: .4s;
}

.dot {
    background-color: #FFFFFF;
    width: 20px;
    height: 20px;
    border-radius: 10px;
    transition: .4s;
}

.wrapper.typeCurrent > .dot {
    background-color: #FF0E0E;
    transition: .4s;
}

.lineAndDot.directionLeft {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    transition: .4s;
}

.lineAndDot.directionRight {
    display: flex;
    flex-direction: row;
    align-items: center;
    transition: .4s;
}

.textArea {
    display: flex;
    flex-direction: column;
    justify-content: center;
    transition: .4s;
}

.textArea.directionLeft {
    align-items: flex-end;
    padding-right: 20px;
    text-align: end;
    transition: .4s;

}

.textArea.directionRight {
    align-items: start;
    padding-left: 20px;
    transition: .4s;
}

.textArea > .guitarLineTitle {
    font-family: sohne-breit-halbfett, sans-serif;
    color: #fff;
    transition: .4s;
}

.wrapper.typeCurrent > .textArea > .guitarLineTitle {
    background: -webkit-linear-gradient(#ff0e0e, #fb5300);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    color: #FF0E0E;
    transition: .4s;
}

.textArea > .guitarLineDescription {
    margin-top: 2px;
    color: #98a0b5;
    font-family: tt norms pro regular, sans-serif;
    word-wrap:break-word;
    transition: .4s;
}

.wrapper.typeCurrent > .textArea > .guitarLineDescription {
    background: -webkit-linear-gradient(#ff0e0e, #fb5300);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    color: #FF0E0E;
    transition: .4s;
}

.textArea > .guitarLineSubDescription {
    color: #98a0b5;
    font-family: tt norms pro regular, sans-serif;
    margin-top: 1px;
    transition: .4s;
    display: none;
}

.wrapper:hover .guitarLineSubDescription{
    display: flex;
    z-index: 1;
}

.wrapper.typeCurrent > .textArea > .guitarLineSubDescription {
    background: -webkit-linear-gradient(#ff0e0e, #fb5300);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    color: #FF0E0E;
    transition: .4s;
}

@media (max-width: 1400px) {
    .line{
        width: 190px;
    }

    .textArea{
        font-size: 13px;
        overflow: hidden;
    }
}

@media (max-width: 1024px) {
    .line{
        width: 240px;
    }

    .textArea > .guitarLineDescription{
        font-size: 14px;
        overflow: hidden;
    }
}

@media (max-width: 768px) { 
    .line{
        width: 60px;
    }

    .dot{
        width: 10px;
        height: 10px;
    }

    .textArea > .guitarLineDescription{
        font-size: 9px;
        overflow: hidden;
    }

    .textArea > .guitarLineTitle{
        font-size: 11px;
    }
}
